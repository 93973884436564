import { createPathComponent } from '@react-leaflet/core';
import L from 'leaflet';
import './MarkerCluster.css';

require('leaflet.markercluster');

const MarkerCluster = createPathComponent(
  ({ children: _c, ...props }, ctx) => {
    // Creating markerClusterGroup Leaflet element
    const markerClusterGroup = new L.markerClusterGroup(props);

    return {
      instance: markerClusterGroup,
      context: { ...ctx, layerContainer: markerClusterGroup },
    };
  }
);

export default MarkerCluster;
