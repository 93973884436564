import React from 'react';
import Link from '../Link/Link';
import './BoxCupInfo.css'

const currDate = new Date();

const nth = function(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const formatDates = (dates) => {
  if (dates && (currDate < new Date(dates.start))) {
    const startDate = new Date(dates.start);
    const endDate = new Date(dates.end);
    return `${startDate.getDate() + nth(startDate.getDate())}${startDate.getMonth() === endDate.getMonth() ? '' : ' '+months[startDate.getMonth()]} - ${endDate.getDate() + nth(endDate.getDate())} ${months[endDate.getMonth()]} ${endDate.getFullYear()}`;
  }
 return '';
}

const getBoxCupDescription = (bc) => {
  let boxCupDescription = '';
  if (bc && bc.description) {
    boxCupDescription = bc.description;
    const currYear = currDate.getFullYear();
    for (let age = 7; age < 50; age++) {
      let subtraction = age;
      if (bc && bc.dates && currDate > bc.dates.end)
        subtraction = age - 1; 
        boxCupDescription = boxCupDescription.replace('${' + age + '}', currYear - subtraction)
    }
  }
  return boxCupDescription;
}

function BoxCupInfo({boxCup}) {
  return (
    <div className='box-cup-info'>
      {boxCup ? (
        <img className='logo' alt={`${boxCup ? boxCup.name : ''} Logo`} src={boxCup ? 'https://jabforms.com/common/images/jablinked-logos/' + boxCup.icon + '.svg' : ''} />
      ) : null}
      <div className='name'>{boxCup ? boxCup.name : ''}</div>
      <div className='location'>{boxCup ? boxCup.location : ''}</div>
      <div className='dates'>{boxCup ? formatDates(boxCup.dates) : ''}</div>
      <div className='description'>{getBoxCupDescription(boxCup)}</div>
      {
        boxCup && boxCup.socials ? (
          <div className='socials'>
            {
              boxCup.socials.map((social, index) => (
                <Link key={index} name={boxCup.name} type={social.type} link={social.link} />
              ))
            }
          </div>
        )
        : null
      }
    </div>
  );
}

export default BoxCupInfo;