import React, { useState, useEffect } from 'react';
import './App.css';

import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, MapConsumer } from 'react-leaflet';

import Drawer from "react-bottom-drawer";

import { getTournaments } from './Services/tournamentService';
import BoxCupInfo from './Components/BoxCupInfo/BoxCupInfo';
import BoxCupMarker from './Components/BoxCupMarker/BoxCupMarker';
import MarkerCluster from './Components/MarkerCluster/MarkerCluster';
import { analytics } from './Services/googleAnalytics';

function App() {
  analytics.pageview();
  // useTracking();
  // analytics.pageview();
  const [boxCups, setBoxCups] = useState([]);

  const retrieveTournaments = () => {
    getTournaments().then(data => {
      if (data)
        setBoxCups(data);
    }).catch(err => console.error(err));
    ;
  }

  useEffect(() => {
    retrieveTournaments();
  }, []);

  const [zoom, setZoom] = useState(6);
  
  const [currentBoxCup, setCurrentBoxCup] = React.useState(undefined);
  const closeDrawer = React.useCallback(() => setCurrentBoxCup(undefined), []);

  return (
    <div className="App">
      <MapContainer center={[51.505, -0.09]} zoom={zoom} maxZoom={15} minZoom={4} scrollWheelZoom >
        <MapConsumer>
          {(map) => {
            map.addEventListener("zoom", e => setZoom(e.target._zoom));
            return null;
          }}
        </MapConsumer>
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />
        {boxCups.filter(bc => bc.jablinkedEvent).map((bc, index) =>
          <BoxCupMarker key={index} zoom={zoom} boxCup={bc} setCurrentBoxCup={setCurrentBoxCup} />
        )}
        <MarkerCluster
          showCoverageOnHover={false}
          spiderfyDistanceMultiplier={6.5}
          maxClusterRadius={50}
        >
          {boxCups.filter(bc => !bc.jablinkedEvent).map((bc, index) =>
            <BoxCupMarker key={index} zoom={zoom} boxCup={bc} setCurrentBoxCup={setCurrentBoxCup} />
          )}
        </MarkerCluster>
      </MapContainer>
      <Drawer
        className='drawer'
        duration={250}
        hideScrollbars={true}
        onClose={closeDrawer}
        isVisible={currentBoxCup !== undefined}
      >
        <BoxCupInfo boxCup={currentBoxCup} />
      </Drawer>
    </div>
  );
}

export default App;
