import React from 'react';
import L from 'leaflet';
import { Marker, useMap } from 'react-leaflet';
import { analytics } from '../../Services/googleAnalytics';

function BoxCupMarker(props) {
  const map = useMap();
  const jablinkedIconSize = props.zoom * 18;
  const independentIconSize = jablinkedIconSize/2;

  const getIcon = ({icon, iconDim, jablinkedEvent, active}) => {
    const widthRatio = iconDim[0]/250;
    const heightRatio = iconDim[1]/250;
    const iconSize = jablinkedEvent ? jablinkedIconSize : independentIconSize;
    const width = iconSize*widthRatio;
    const height = iconSize*heightRatio;

    return new L.Icon({
      iconUrl: 'https://jabforms.com/common/images/jablinked-logos/' + icon + '.svg',
      iconRetinaUrl: 'https://jabforms.com/common/images/jablinked-logos/' + icon + '.svg',
      iconSize: [width, height],
      iconAnchor: [width/2, height/2],
      className: active ? undefined : "currently-inactive"
    });
  }

  return (
    <Marker
      position={props.boxCup.latLng}
      icon={getIcon(props.boxCup)}
      eventHandlers={{
        click: () => {
          map.setView(props.boxCup.latLng, props.zoom, {
            animate: true,
            pan: {
              duration: 1.5
            }
          })
          analytics.boxCupInfo(props.boxCup.name);
          props.setCurrentBoxCup(props.boxCup);
        }
      }}
      zIndexOffset={props.boxCup.jablinkedEvent ? (1000 - props.boxCup.priority) * 1000  : 0}
    />
  );
}

export default BoxCupMarker;